<template>
  <div class="position-relative w-100 p-3">
    <div class="d-flex justify-content-between" v-if="!hideHeader">
      <label for="">รายการสินค้า</label>
      <b-dropdown
        id="dropdown-left"
        right
        :text="'ตัวเลือกเพิ่มเติม'"
        variant="outline-dark"
        class="lang-dd"
      >
        <b-dropdown-item-button @click="openModal">
          เพิ่มสินค้า
        </b-dropdown-item-button>
      </b-dropdown>
    </div>
    <b-row class="mt-2">
      <b-col>
        <b-table
          class="table-list"
          striped
          responsive
          hover
          :items="productGroups"
          :fields="fields"
          :busy="isBusy"
          show-empty
          empty-text="ไม่พบข้อมูล"
        >
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
          <template v-slot:cell(name)="data">
            <p class="mb-0 nobreak two-lines">{{ data.item.name }}</p>
          </template>
          <template v-slot:cell(sku)="data">
            <p class="mb-0 nobreak two-lines">{{ data.item.sku }}</p>
          </template>
          <template v-slot:cell(urlKey)="data">
            <p class="mb-0 nobreak two-lines">{{ data.item.urlKey }}</p>
          </template>
          <template v-slot:cell(price)="data">
            <span> ฿ {{ data.item.price | numeral("0,0.00") }} </span>
          </template>
          <template v-slot:cell(discount)="data">
            <InputText
              className="mb-0"
              :textFloat="''"
              :placeholder="'ระบุส่วนลด'"
              type="number"
              v-model="data.item.discount"
              :isValidate="
                v.productGroups.$each.$iter[data.index].discount.$error
              "
              :v="v.productGroups.$each.$iter[data.index].discount"
            />
            <span
              v-if="
                v.productGroups.$each.$iter[data.index].discount.required ==
                false
              "
              class="text-danger"
              >กรุณากรอกข้อมูล</span
            >
          </template>
          <template v-slot:cell(rawPrice)="data">
            <span v-if="promotionDiscountId == 1">
              ฿
              {{
                (data.item.price - parseFloat(data.item.discount))
                  | numeral("0,0.00")
              }}
            </span>
            <span v-else-if="promotionDiscountId == 2">
              ฿
              {{
                ((data.item.price * (100 - parseFloat(data.item.discount))) /
                  100)
                  | numeral("0,0.00")
              }}
            </span>
            <span v-else-if="promotionDiscountId == 3">
              ฿ {{ data.item.discount | numeral("0,0.00") }}
            </span>

            <!-- <span
              v-if="
                v.productGroups.$each.$iter[data.index].discount
                  .priceAfterDiscount == false
              "
              class="text-danger"
              ><br />ราคาต้องมากกว่าหรือเท่ากับ 0</span
            > -->
          </template>
          <template v-slot:cell(action)="data">
            <font-awesome-icon
              @click="deleteData(data.item.productId)"
              icon="times-circle"
              class="text-secondary delete-icon pointer"
            />
          </template>
        </b-table>
      </b-col>
    </b-row>
    <!-- <b-row
      class="no-gutters px-3 px-sm-0"
      v-if="!hidePaginate && productGroups.length > 0"
    >
      <b-col
        class="form-inline justify-content-center justify-content-sm-between"
      >
        <div class="d-sm-flex m-3">
          <b-pagination
            v-model="filter.PageNo"
            :total-rows="rows"
            :per-page="filter.PerPage"
            class="m-md-0"
            @change="pagination"
            align="center"
          ></b-pagination>
        </div>

        <b-form-select
          class="mr-sm-3 select-page"
          v-model="filter.PerPage"
          @change="hanndleChangePerpage"
          :options="pageOptions"
        ></b-form-select>
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";

export default {
  components: {
    InputText,
  },
  props: {
    hideHeader: {
      required: false,
      type: Boolean,
      default: false,
    },
    hidePaginate: {
      required: false,
      type: Boolean,
      default: false,
    },
    index: {
      required: false,
      type: Number,
    },
    fields: {
      required: true,
      type: Array,
    },
    productGroups: {
      required: false,
      type: Array,
      default: [],
    },
    promotionDiscountId: {
      required: false,
      type: [String, Number],
      default: 0,
    },
    v: {
      required: true,
      type: [Object],
    },
  },
  watch: {
    productGroups: {
      async handler(e) {
        if (e.length > 0) {
          for (let index = 0; index < e.length; index++) {
            e[index].rawPrice = e[index].discount
              ? parseFloat(e[index].price) - parseFloat(e[index].discount)
              : parseFloat(e[index].price);
          }
          if (this.checkValidate) {
            for (let index = 0; index < e.length; index++) {
              if (e[index].discount && e[index].discount !== "") {
                this.error[index] = false;
              } else this.error[index] = true;
            }
          }
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      filter: {
        PageNo: 1,
        PerPage: 10,
        Search: "",
      },
      error: [],
      isBusy: false,
      checkValidate: false,
      rows: 0,
    };
  },
  methods: {
    openModal() {
      this.$emit("openModal");
    },
    deleteData(id) {
      this.$emit("deleteData", id, this.index);
    },
  },
};
</script>

<style scoped>
label {
  color: #16274a;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
  width: 100%;
}

.text-danger {
  color: #ff0000 !important;
  font-size: 14px;
}

::v-deep .dropdown-menu-right {
  padding: 0rem !important;
}

::v-deep .dropdown-item {
  padding: 0.75rem 1.25rem !important;
}

::v-deep .lang-dd button:focus {
  background-color: #fff !important;
  color: #4f5d73;
}

::v-deep .lang-dd ul {
  width: 100px;
}
</style>
